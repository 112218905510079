<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-card uk-card-default uk-card-body uk-width-3-4@m uk-margin-auto">
            <h3 class="uk-card-title">Report List</h3>
            <hr>
            <div class="uk-form-controls uk-width-auto">
                <div class="uk-margin">
                    <button class="uk-button uk-button-primary" type="button" @click="showOfficeListModal">Office List Report</button>
                </div>
            </div>
        </div>

        <div id="officeListModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideOfficeListModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Office List Filter</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label">Company</label>
                        <multiselect
                        v-model="officeListForm.company_id"
                        label="name"
                        name="company_id"
                        track-by="value"
                        :options="company_options"
                        >
                        </multiselect>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-primary" type="button" @click="downloadOfficeListReport">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import formatter from '@/utils/formatter';
import { excelDownloader } from '@/utils/helper';

export default {
    data() {
        return {
            meta: {
                roles: '',
                company_id: null,
                company_office_id: null
            },
            isFetching: false,
            isLoading: false,
            company_options: [],
            office_options: [],
            roles_options: [
                { name: 'Candidate', value: 'zxc123'},
                { name: 'Employer', value: 'asd123'}
            ],
            company: {
                offices: [],
            },
            officeListForm: {
                company_id: null
            },
        };
    },
    computed: {
        ...mapGetters({
            companies: 'company/companies',
            company_data: 'company/company',
        })
    },
    mounted() {
        this.setCompanyValues();
    },
    methods: {
        ...mapActions({
            getCompanies: 'company/getCompanies',
            getOfficeReport: 'report/getOfficeReport',
        }),
        async setCompanyValues() {
            this.isFetching = true;

            await this.getCompanies();
            this.company_options = this.companies.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async downloadOfficeListReport() {
            this.isLoading = true;
            let param = {
                company_id: this.officeListForm.company_id != null ? this.officeListForm.company_id.value : '',
            };
            let all_recaps = await this.getOfficeReport(param);
            // console.log(all_recaps);
            for (const item of all_recaps) {
                item['Created At'] = formatter.datetimeShort(item['Created At']);
            }

            excelDownloader(all_recaps, this.generateFileName("Office List", this.officeListForm));
            this.isLoading = false;
        },
        generateFileName(report_type, param) {
            let suffixName = "";
            switch (report_type) {
            case "Office List":
                suffixName = param.company_id != null ? `${suffixName} @ ${param.company_id.name}` : '';
                break;
            }
            let filename = `${report_type} Report${suffixName}.xls`;
            return filename;
        },
        async showOfficeListModal() {
            await window.UIkit.modal('#officeListModal').show();
        },
        async hideOfficeListModal() {
            await window.UIkit.modal('#officeListModal').hide();
            this.resetForm();
        },
        resetForm() {
            this.officeListForm = {
                company_id: null
            };
        },
    },
};
</script>
